import assert from 'assert'
import { Grid, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import { graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { StaticImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import * as React from 'react'
import { Layout } from '../components/Layout'
import { SEO } from '../components/SEO'
import { Txt } from '../components/Txt'
import type { AfterServiceHeroImageQuery } from '../../types/graphql-types'
import type { PageProps } from 'gatsby'

export const Head = () => (
    <SEO
        title="結婚指輪、婚約指輪のアフターサービス｜安い、安心、高品質のミスプラチナ"
        description="安い結婚指輪、婚約指輪は試着もできる安心通販のMissPlatinum。結婚指輪１万円台～！婚約指輪６万円台～！と安い価格帯で高品質な人気のブライダルリングはアフターフォローも万全♪"
        page="afterservice"
    />
)

const AfterServicecPage: React.FC<PageProps<AfterServiceHeroImageQuery>> = ({
    data,
}) => {
    const muiTheme = useTheme()
    const imageData = data.file?.childImageSharp?.gatsbyImageData
    assert(imageData)
    const bgImage = convertToBgImage(imageData)

    return (
        <Layout breadcrumbs={[{ name: 'アフターサービス' }]}>
            <Grid container spacing={0}>
                <Grid item xs={12} component="main">
                    <Hero Tag="section" {...bgImage}>
                        <HeroTextWrapper>
                            <H1>
                                <Txt size="xl">アフターサービス</Txt>
                            </H1>
                            <Txt size="xs">afterservice</Txt>
                        </HeroTextWrapper>
                    </Hero>
                    <Wrapper>
                        <H3 theme={muiTheme}>
                            <Txt size="s">サイズ直し</Txt>
                        </H3>
                        <Body theme={muiTheme}>
                            <ImageWrapper theme={muiTheme}>
                                <StaticImage
                                    src="../images/afterservice/afterservice_02.jpg"
                                    alt=""
                                    width={400}
                                    objectFit="contain"
                                />
                            </ImageWrapper>
                            <Description>
                                <Txt size="s">
                                    サイズ直しは１回あたり5,400円、納期は10日前後でのご案内になります。
                                </Txt>
                                <br />
                                <Txt size="s">
                                    納品してすぐの破損や汚損、サイズの違和感があっても安心、フリーメンテナンス保証も有償オプションでご利用頂けます。
                                </Txt>
                            </Description>
                        </Body>
                    </Wrapper>
                    <Wrapper>
                        <H3 theme={muiTheme}>
                            <Txt size="s">新品仕上げ</Txt>
                        </H3>
                        <Body theme={muiTheme}>
                            <ImageWrapper theme={muiTheme}>
                                <StaticImage
                                    src="../images/afterservice/afterservice_03.jpg"
                                    alt=""
                                    width={400}
                                    objectFit="contain"
                                />
                            </ImageWrapper>
                            <Description>
                                <Txt size="s">
                                    プラチナリングは通常、水やお湯などでは変色しない材質ですが他の金属同様、長く使い続けると細かい傷や汚れにより光沢は落ちていくことが一般的です。
                                </Txt>
                                <br />
                                <Txt size="s">
                                    照りが落ち着いた状態が年季を感じて指に馴染むというケースもありますが、輝きを戻したい場合は新品仕上げがおすすめです。
                                </Txt>
                                <br />
                                <Txt size="s">
                                    工賃は1本5,400円(税込）納期はお預かり日から5日ほど頂いております。
                                </Txt>
                            </Description>
                        </Body>
                    </Wrapper>
                    <Wrapper>
                        <H3 theme={muiTheme}>
                            <Txt size="s">石留め加工</Txt>
                        </H3>
                        <Body theme={muiTheme}>
                            <ImageWrapper theme={muiTheme}>
                                <StaticImage
                                    src="../images/afterservice/afterservice_04.jpg"
                                    alt=""
                                    width={400}
                                    objectFit="contain"
                                />
                            </ImageWrapper>
                            <Description>
                                <Txt size="s">
                                    あまり多いケースではありませんがプラチナやゴールドも他の金属同様、強い圧や衝撃が加われば変形する可能性もございます。
                                </Txt>
                                <br />
                                <Txt size="s">
                                    万が一変形、破損してしまった場合もお気軽にご相談下さい。
                                </Txt>
                                <br />
                                <Txt size="s">
                                    状態にはよりますが変形による形状修復は１本あたり5,400円から承っております。
                                </Txt>
                            </Description>
                        </Body>
                    </Wrapper>
                    <Wrapper>
                        <H3 theme={muiTheme}>
                            <Txt size="s">石留め・その他加工</Txt>
                        </H3>
                        <Body theme={muiTheme}>
                            <ImageWrapper theme={muiTheme}>
                                <StaticImage
                                    src="../images/afterservice/afterservice_05.jpg"
                                    alt=""
                                    width={400}
                                    objectFit="contain"
                                />
                            </ImageWrapper>
                            <Description>
                                <Txt size="s">
                                    万が一ダイヤや色石が取れてしまった場合はお直しをさせて頂きます。（別途石代がかかる場合がございます）
                                </Txt>
                                <br />
                                <Txt size="s">
                                    また、ミル打ちやつや消しといった表面加工、ダイヤや誕生石の追加などもお気軽にご相談頂いております。
                                </Txt>
                            </Description>
                        </Body>
                    </Wrapper>
                </Grid>
            </Grid>
        </Layout>
    )
}

export const query = graphql`
    query AfterServiceHeroImage {
        file(relativePath: { eq: "afterservice/afterservice_01.jpg" }) {
            childImageSharp {
                gatsbyImageData(formats: [WEBP])
            }
        }
    }
`

const Hero = styled(BackgroundImage)({
    height: '235px',
    position: 'relative',
})
const HeroTextWrapper = styled('div')({
    position: 'absolute',
    top: '178px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    textShadow: '0 0 4px #333333',
    color: '#ffffff',
})
const H1 = styled('h1')({
    margin: 0,
})
const Wrapper = styled('section')({
    marginTop: '40px',
})
const H3 = styled('h3')(({ theme }) => ({
    margin: 0,
    padding: '10px',
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.contrastText,
    lineHeight: 1,
}))
const Body = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
    },
}))
const ImageWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    margin: 0,
    [theme.breakpoints.up('sm')]: {
        maxWidth: '320px',
        minWidth: '320px',
    },
}))
const Description = styled('p')({
    margin: '12px',
})

export default AfterServicecPage
